<template>
    <v-container class="pa-4 py-8">
      <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
        <v-icon class="ml-2">mdi-chart-bar</v-icon> View IRA Assessment Results
            <v-btn color="primary" class="ml-4 float-right text-body-1" @click="coverageDrawer = !coverageDrawer">
                <v-icon class="mr-1">mdi-calendar-multiple</v-icon>
                Filter Coverage
            </v-btn>   
      </div>
        
        <v-row>
          <v-col lg="12">
            <h2 class="text-center">RISKS</h2>
            <v-simple-table>
              <template v-slot:default>
                <thead class="primary white--text">
                  <tr>
                    <th rowspan="2" class="text-center" style="color: white; font-size:30px">Risks</th>
                    <th :colspan="coverageArr.length" class="text-center" style="color: white; font-size:20px;" v-for="(item, index) in DepartmentsIR" :key="index">{{item.Department}}</th>
                  </tr>
                  <tr class="text-center" style="color: white; font-size:20px;">
                    <th class="text-center" style="color: white;" v-for="(item, index) in returnDatesRisk()" :key="index">{{item.Date}}</th>
                  </tr>
                  
                </thead>
        
                <tr v-for="(item,index) in Risks" :key="index">
                  <td>{{item.Risks}}</td>
                  <td class="text-center" v-for="(returnDate, index) in returnDatesRisk()" :key="index">{{returnAveLevel(item, returnDate)}}</td>
                </tr>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="12">
            <h2 class="text-center">Controls</h2>
            <v-simple-table>
              <template v-slot:default>
                <thead class="primary white--text">
                  <tr>
                    <th :colspan="coverageArr.length" class="text-center" style="color: white; font-size:30px" v-for="(section, index) in returnControlIndicators" :key="index">{{section.Section}}</th>
                  </tr>
                  <tr class="text-center" style="color: white; font-size:20px;">
                   <th class="text-center" style="color: white;" v-for="(item, index) in returnDatesControl()" :key="index">{{item.Date}}</th>
                  </tr>
                </thead>
                   <tr>
                      <td class="text-center" v-for="(date, index) in returnDatesControl()" :key="index">{{returnAveLevelCC(date)}}</td>                    
                  </tr>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row> 
          <v-col lg="12">
            <h2 class="text-center">Residual Risk</h2> 
          </v-col>
           
          <v-col class="text-center colPadding" v-for="(item) in returnDate" :key="'N' + item.id">
                <h3>{{item.startDate}}</h3>
                <span :class="returnResidualClass(returnAveLevelFinal(item.startDate))">{{returnAveLevelFinal(item.startDate)}}</span>
          </v-col>
        </v-row>
        <v-navigation-drawer
        absolute
        right
        temporary
        width="350"
        v-model="coverageDrawer"
    >
    <div class="text-h6 pa-4 grey--text text--darken-2">
        <v-icon class="mr-2">mdi-calendar-multiple</v-icon>
        Filter Coverage
    </div>
        <v-timeline
            dense
            clipped
            class="pr-8"
        >
            <v-timeline-item
                v-for="event in returnCoverage"
                :key="event['.key']"
                class="mb-4"
                :class="isIncluded(event['.key']) ? 'primary lighten-2' : ''"
                style="cursor:pointer;"
                :color="isIncluded(event['.key']) ? 'primary' : 'grey'"
                small
                @click.native="coverageClickHandler(event['.key'])"
            >
                <template v-slot:icon>
                    <v-icon class="white--text" v-show="isIncluded(event['.key'])">mdi-check</v-icon>
                </template>
                <v-row justify="space-between">
                    <v-col
                    cols="7"
                    v-text="event.startDate"
                    ></v-col>
                    <v-col
                    class="text-right text-caption pr-8"
                    cols="5"
                    >
                        {{$moment(event.CreatedAt.toDate()).format('MM/DD/YYYY HH:mm A')}}
                    </v-col>
                </v-row>
            </v-timeline-item>

        </v-timeline>
    </v-navigation-drawer>
    </v-container>
</template>

<script>
export default {
    data () {
      return {
        coverageDrawer: false,
        coverageFilterKey: null,
        coverageArr: []
      }
    },
     firestore(){
        return {
            RiskImpactLevel: this.$db.collection('RiskImpactLevel').orderBy('Level','asc'),
            LikelihoodLevel: this.$db.collection('LikelihoodLevel').orderBy('Level','desc'),
            ORMP: this.$db.collection('ORMP').orderBy('Vulnerability','desc'),
            ORMPLevels: this.$db.collection('ORMPLevels'),
            IRACoverage: this.$db.collection('IRACoverage'),
            DepartmentsIR: this.$db.collection('Department-IR'),
            Risks: this.$db.collection('Risks-IR'),
            IRAReportIR: this.$db.collection('IRAReport-IR'),
            IRAReportCC: this.$db.collection('IRAReport-CC'),
            RiskIndicatorsIR: this.$db.collection('RiskIndicators-IR'),
            ControlIndicatorsCC: this.$db.collection('ControlIndicators-CC')
        }
    },
    computed: {
        returnControlIndicators(){
          if(this.ControlIndicatorsCC.length == 0){
            return []
          }
          let indicators = this.ControlIndicatorsCC
          let mapped = indicators.map(e=>{
            return {Section: e.Section}
          })
          return this.$lodash.uniqBy(mapped, 'Section')
        },
         returnDate(){
            let arr = []
            if(this.coverageArr.length == 0){
              return []
            }
            this.returnMovementORMP.forEach(e=>{
              arr.push({startDate: e.startDate, id:this.generateRandom()})
            })
            return this.$lodash.uniqBy(arr, 'startDate')
          },
          
          returnMovementORMP(){
            // get department if ever
            // let key = null
            // if(this.coverageArr.length == 0 && this.returnCoverage.length > 0) {
            //     key = this.returnCoverage[0]['.key']
            //     this.passOnValue(key)
            // } 

            let coverageFilter = this.IRAReportIR.filter(a=>{
                return this.coverageArr.includes(a.IRACoverageId)
            })

           let mapped = coverageFilter.map(e=>{
                let x  = {...e}
                x.startDate = this.returnStartDate(e)
                return x
            })
            return mapped
        },
           returnMovementORMPCC(){
            // get department if ever
            // let key = null
            // if(this.coverageArr.length == 0 && this.returnCoverage.length > 0) {
            //     key = this.returnCoverage[0]['.key']
            //     console.log(key)
            //     this.passOnValue(key)
            // } 

            let coverageFilter = this.IRAReportCC.filter(a=>{
                return this.coverageArr.includes(a.IRACoverageId)
            })

           let mapped = coverageFilter.map(e=>{
                let x  = {...e}
                x.startDate = this.returnStartDate(e)
                return x
            })
            return mapped
        },
       returnCoverage(){
            if(this.returnIRAFilter == false){
                let filter = this.IRACoverage.filter(a=>{
                    return a.Department = this.$store.getters['useraccount/isAuthenticated'].department
                })   
                return this.$lodash.orderBy(filter,'CreatedAt','desc')
            }

            let deptFilter = this.IRACoverage.filter(a=>{
                return a.Department == this.deptFilter
            })

            return this.$lodash.orderBy(deptFilter,'CreatedAt','desc')
        },
        returnIRAFilter(){
            if(this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM') {
                return false
            }
            return true
        },
           returnDept(){
            let db = this.Departments.map(a=>{
                return {
                    text: a.departmentName,
                    value: a.departmentName
                }
            })
            return db
        },
    },
    methods: {
        returnResidualClass(residual){
        if(residual == "LOW RISK"){
          return 'residualRiskLow'
        } else if(residual == "MEDIUM RISK"){
          return 'residualRiskMedium'
        } else if(residual == "HIGH RISK"){
          return 'residualRiskHigh'
        }
      },
        returnDepts(item){
            let filtered = this.returnMovementORMP.filter(e=>{
              return e.Department == item.Department
            })
            let mapped = filtered.map(e=>{
              return {Department: e.Department, Date: e.startDate}
            })
            let dateArr = this.$lodash.uniqBy(mapped, 'Date')
            
            return this.$lodash.orderBy(dateArr,'Date','asc')
        },

        returnSects(item){
           let filtered = this.returnMovementORMPCC.filter(e=>{
              return e.Section == item.Section
            })
            let mapped = filtered.map(e=>{
              return {Section: e.Section, Date: e.startDate}
            })
            let dateArr = this.$lodash.uniqBy(mapped, 'Date')
            
            return this.$lodash.orderBy(dateArr,'Date','asc')
        },
      
        returnDatesControl(){
           let arr = []
          if(this.coverageArr.length == 0){
              return []
            }
          this.returnControlIndicators.forEach(e=>{
              this.returnSects(e).forEach(f=>{
                arr.push(f)
              })
          })
          return arr
        },
           
        returnDatesRisk(){ 
            // let i
            // let arr2 = []
           if(this.DepartmentsIR.length == 0){
             return []
           }
            let arr = []
            if(this.coverageArr.length == 0){
              return []
            }
      
            this.DepartmentsIR.forEach(e=>{
              this.returnDepts(e).forEach(f=>{
                arr.push(f)
              })
            })        
            // filtered.forEach(e=>{
            //   arr.push({Date: e.startDate, Department: e.Department})
            // })
            // let totalLength = this.DepartmentsIR.length
          
            // console.log(dateArr)
            //console.log(arr)
           
            //   for(i=0; i<totalLength; i++){
            //     arr2.push(dateArr)
            //   }
            // console.log(arr)
            // console.log(arr2)
            return arr
          },

 

      generateRandom(){
          var firstPart = (Math.random() * 46656) | 0
          var secondPart = (Math.random() * 46656) | 0
          firstPart = ("000" + firstPart.toString(36)).slice(-3)
          secondPart = ("000" + secondPart.toString(36)).slice(-3)
          return firstPart + secondPart
      },
      returnStartDate(obj){
        let filter = this.IRACoverage.filter(e=>{
            return e['.key'] == obj.IRACoverageId
        })
        return filter[0].startDate
      },
      isIncluded(key){
        return this.coverageArr.includes(key)
      },
      coverageClickHandler(key){
        if(this.coverageArr.includes(key)){
            this.coverageArr.splice(this.coverageArr.indexOf(key), 1)
        } else {
          this.coverageArr.push(key)
        }
      },
        passOnValue(key){
            this.coverageArr.push(key)
        },
        returnAveLevel(item, returnDate){
          let arr = []
          let filter = this.returnMovementORMP.filter(e=>{
            return e.Risks == item.Risks && e.Department == returnDate.Department && e.startDate == returnDate.Date
          })
          filter.forEach(e=>{
            arr.push(this.returnMetric(e))
          })
          return this.returnTotalRating(arr) 
        },
        returnAveLevelFinal(date){
          let arrAverage = []
          let filter = this.returnMovementORMP.filter(e=>{
            return e.startDate == date
          })
          let filter2 = this.returnMovementORMPCC.filter(e=>{
            return e.startDate == date
          })
          let mappedCC = filter2.map(e=>{
            return e.Score
          })
          let totalCC = mappedCC.reduce((a, b) => a + b, 0)
          let control = this.returnRatingCC(totalCC)
          let indicators = this.RiskIndicatorsIR
          indicators.forEach(e=>{
            e.Average = this.averagePerIndicator(e, filter)
          })
          let risks = this.Risks
          risks.forEach(e=>{
            e.Average = this.averagePerRisk(e, indicators)
          })
          risks.forEach(e=>{
            arrAverage.push(e.Average)
          })
          let inherent = this.returnTotalRatingFinal(this.returnRating(arrAverage))
          console.log(inherent)
          return this.returnFinalRating(inherent, control)
          
        },

        returnFinalRating(inherent, control){
        if(inherent == "LOW" && control == "STRONG" || inherent == "LOW" && control == "ACCEPTABLE" || inherent == "MEDIUM" && control == "STRONG"){
          return "LOW RISK"
        } else if(inherent == "LOW" && control == "WEAK" || inherent == "MEDIUM" && control == "ACCEPTABLE" || inherent == "HIGH" && control == "STRONG"){
          return "MEDIUM RISK"
        } else if(inherent == "MEDIUM" && control == "WEAK" || inherent == "HIGH" && control == "WEAK" || inherent == "HIGH" && control == "ACCEPTABLE"){
          return "HIGH RISK"
        }
      },
         returnRatingCC(total){
          if(total <= 5){
            return 'WEAK'
          } else if(total < 29.99){
            return 'ACCEPTABLE'
          } else if(total >= 30) {
            return 'STRONG'
          }
          
         },
        returnRating(arr){
          let arr2 = []
          arr.forEach(e=>{
            if(e <= 2){
            arr2.push('Low')
          } else if(e < 4.99){
            arr2.push('Medium')
          } else if(e === 5) {
            arr2.push('High')
          }
        
          })
          return arr2
        },
        returnTotalRatingFinal(arr){
        let i
        let numHighCount = 0
        let numMedCount = 0
        let numLowCount = 0
        for(i=0; i < arr.length; i++){
          if(arr[i] === "High"){
            numHighCount++
          } else if(arr[i] === "Medium"){
            numMedCount++
          } else if(arr[i] === "Low"){
            numLowCount++
          }
        }
        if(numLowCount === 3 && numMedCount == 1 || numLowCount == 4){
          return "LOW"
        } else if(numLowCount == 1 && numMedCount == 2 && numHighCount == 1 || numLowCount == 2 && numMedCount == 2 || numMedCount == 3 && numHighCount == 1 || numLowCount == 2 && numMedCount == 1 && numHighCount == 1 || numLowCount == 1 && numMedCount == 3 || numMedCount == 4){
          return "MEDIUM"
        } else if (numMedCount == 2 && numHighCount == 1 || numHighCount == 1 && numMedCount == 1 && numHighCount == 2 || numLowCount == 2 && numHighCount == 2 || numMedCount == 2 && numHighCount == 2 || numLowCount == 1 && numHighCount == 3 || numMedCount == 1 && numHighCount == 3 || numHighCount == 4){
          return "HIGH"
        }
      },
        averagePerIndicator(item, arrayToFilter){
          let arr = []
          let i
          let nullCount = 0
          let newArrayLength
          let filter = arrayToFilter.filter(e=>{
            return e.Indicator == item.Indicator
          })
          filter.forEach(e=>{
            arr.push(e.Likelihood)
          })
          for(i=0; i < arr.length; i++){
          if(arr[i] === null){
            nullCount++
          }
          newArrayLength = arr.length - nullCount
        }
         let result = (arr.reduce((a, b) => a + b, 0))/newArrayLength
         return result
        },

        averagePerRisk(item, arrayToFilter){
          let arr = []
          let i
          let nullCount = 0
          let newArrayLength
          let filter = arrayToFilter.filter(e=>{
            return e.Risks == item.Risks
          })
          filter.forEach(e=>{
            arr.push(e.Average)
          })
          for(i=0; i < arr.length; i++){
          if(arr[i] === null){
            nullCount++
          }
          newArrayLength = arr.length - nullCount
        }
         let result = (arr.reduce((a, b) => a + b, 0))/newArrayLength
         return result
        },


     
        returnAveLevelCC(item){
          if(item.Section == 'PEOPLE'){
            let arr = []
            let filter = this.returnMovementORMPCC.filter(e=>{
              return e.startDate == item.Date && e.Section == item.Section
            })
            filter.forEach(e=>{
              arr.push(e.Score)
            })
            let result = arr.reduce((a, b) => a + b, 0)
            console.log(arr)
            console.log(result)
            return this.returnRatingPeople(result)
          } else {
            let arr = []
            let filter = this.returnMovementORMPCC.filter(e=>{
              return e.startDate == item.Date && e.Section == item.Section
            })
            filter.forEach(e=>{
              arr.push(e.Score)
            })
            let result = (arr.reduce((a, b) => a + b, 0))
            return this.returnRatingProcessAndTechnology(result)
          }
        },
         returnRatingPeople(total){
          if(total < 5){
            return 'WEAK'
          } else if(total < 9){
            return 'ACCEPTABLE'
          } else if(total >= 9) {
            return 'STRONG'
          }
         },
          returnRatingProcessAndTechnology(total){
          if(total < 12){
            return 'WEAK'
          } else if(total < 22){
            return 'ACCEPTABLE'
          } else if(total >= 23) {
            return 'STRONG'
          }
         },
        returnMetric(item){
         if(item.Likelihood === 5){
            return "HIGH"
          } else if(item.Likelihood <= 2 || item.Likelihood == 0 || item.Likelihood == null){
            return "LOW"
          } else if(item.Likelihood >= 3){
            return "MEDIUM"
          } 
        },
        returnTotalRating(arr){
        let i
        let numHighCount = 0
        let numMedCount = 0
        let numLowCount = 0

        for(i=0; i < arr.length; i++){
          if(arr[i] === "HIGH"){
            numHighCount++
          } else if(arr[i] === "MEDIUM"){
            numMedCount++
          } else if(arr[i] === "LOW"){
            numLowCount++
          }
        }
        if(arr.length == 1){
          return arr[0]
        } else if(arr.length == 2){
          if(numLowCount == 2){
            return "LOW"
          } else if(numLowCount == 1 && numHighCount == 1 || numLowCount == 1 && numMedCount == 1 || numMedCount == 2){
            return "MEDIUM"
          } else if(numHighCount == 2 || numMedCount == 1 && numHighCount == 1){
            return "HIGH"
          }
        }
        if(numLowCount == 6 || numLowCount == 5 && numMedCount == 1 || numLowCount == 4 && numMedCount == 2){
          return "LOW"
        } else if(numLowCount == 5 && numHighCount == 1 || numLowCount == 4 && numHighCount == 2 || numLowCount == 4 && numMedCount == 1 && numHighCount == 1 || numLowCount == 3 && numMedCount == 1 && numHighCount == 2 || numLowCount == 3 && numMedCount == 2 && numHighCount == 1 || numLowCount == 3 && numMedCount == 3 || numLowCount == 2 && numMedCount == 2 & numHighCount == 2 || numLowCount == 2 && numMedCount == 3 && numHighCount == 1 || numLowCount == 2 && numMedCount == 4 || numLowCount == 1 && numMedCount == 3 && numHighCount == 2 || numLowCount == 1 && numMedCount == 4 && numHighCount == 1 || numLowCount == 1 && numMedCount == 5 || numMedCount == 5 && numHighCount == 1 || numMedCount == 6){
          return "MEDIUM"
        } else if (numLowCount == 3 && numHighCount == 3 || numLowCount == 2 && numHighCount == 4 || numLowCount == 2 &&  numMedCount == 1 && numHighCount == 3 || numLowCount == 1 && numHighCount == 5 || numLowCount == 1 && numMedCount == 1 && numHighCount == 4 || numLowCount == 1  && numMedCount == 2 && numHighCount == 3 || numHighCount == 6 || numMedCount == 1 && numHighCount == 5 || numMedCount == 2 && numHighCount == 4 || numMedCount == 3 && numHighCount == 3 || numMedCount == 4 && numHighCount == 2){
          return "HIGH"
        }
      }

    }
}
</script>
<style scoped>
h3.solid {border-style: solid !important;}
table, th, td {
  border: 2px solid black;
}
.colPadding{
  padding-right: -40px;
}
.residualRiskLow{
    background-color: #00C851;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 25px;
    padding-top:0.3em;
    padding-bottom:0.3em;
    padding-right: 0.3em;
    padding-left: 0.3em;
    color: white;
  }
    .residualRiskMedium{
    background-color: #ffbb33;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 25px;
    padding-top:0.3em;
    padding-bottom:0.3em;
    padding-right: 0.3em;
    padding-left: 0.3em;
    color: white;
  }
    .residualRiskHigh{
    background-color: #ff4444;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 25px;
    padding-top:0.3em;
    padding-bottom:0.3em;
    padding-right: 0.3em;
    padding-left: 0.3em;
    color: white;
  }
</style>